.AddMenu {
  width: 100%;
  display: flex;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #e7e7e7;
    position: absolute;
    left: 0;
    bottom: 1px; }
  &-Content {
    width: 100%;
    padding-bottom: 3px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    &::-webkit-scrollbar {
      height: 0; }
    &::-webkit-scrollbar-thumb {
      background-color: $accent-color;
      border-radius: 0;
      &:hover {
        background-color: rgb(196, 196, 196); } }
    &::-webkit-scrollbar-track {
      background-color: rgb(237, 237, 237); } }
  &-Wrap {
    display: flex;
    justify-content: flex-start; }
  &-Item {
    height: 40px;
    margin-right: 45px;
    font-size: 18px;
    color: #aeaeae;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    &:last-of-type {
      margin-right: 0; }
    &:hover {
      color: $main-color; }
    &.active {
      color: $main-color;
      &:before {
        content: '';
        width: 100%;
        height: 2px;
        background: #26a9e0;
        position: absolute;
        left: 0;
        bottom: -2px; } } } }
