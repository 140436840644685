.Header {
  width: 100%;
  background-color: $accent-color;
  .Container {}
  &-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &-Logo {
    width: 155px;
    padding: 10px 0;
    background-color: $main-color;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .Logo {
      width: 81px;
      height: 53px;
      display: block; } }
  &-Left {
    display: flex;
    align-items: center; }
  &-Nav {
    margin-left: 35px; } }

.HeaderNav {
  display: flex;
  &-Item {
    margin-right: 20px;
    &:last-child {
      margin-right: 0; } }
  &-Link {
    width: 91px;
    height: 39px;
    font-size: 18px;
    color: #ffffff;
    border-radius: 3px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      text-decoration: underline; }
    &.active {
      background-color: $main-color; } } }
