.Table {
  &-Row {
    border-bottom: 1px solid #e9e9e9;
    @include mf(sm) {
      display: flex; }
    &:last-child {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      border: none; }
    &_link {
      text-decoration: none;
      &:hover {
        background-color: #dbe9eb; } }
    &_head {
      margin-bottom: 0;
      padding-bottom: 0;
      border-color: transparent;
      display: none;
      @include mf(sm) {
        margin-bottom: 0;
        border-bottom: none;
        display: flex; }
      .Table-Column {
        margin-bottom: 0; }
      .Table-Text {
        color: #898989;
        &_label {
          display: none;
          @include mf(sm) {
            display: inline-block; } } } }
    &:last-child {
      border-bottom: none; } }
  &-Column {
    margin-bottom: 20px;
    padding: 5px 15px 5px 15px;
    @include mf(sm) {
      margin-bottom: 0; }
    &:last-of-type {
      margin-bottom: 0; }
    &_empty {
      margin: 0;
      padding: 0; } }
  &-Text {
    font-size: 14px;
    color: #000000;
    &-Link {
      font-weight: 500;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } }
    &_label {
      margin-bottom: 10px;
      font-size: 14px;
      color: #898989;
      @include mf(sm) {
        margin-bottom: 0;
        display: none; } } } }

