.Input_theme_measure {
  .Input-Wrap {
    position: relative; }
  .Input-Measure {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      position: absolute;
      top: 8px;
      right: 5px; }
  .Input-Control {
    width: 100%; } }
