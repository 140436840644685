.Flex {
  display: flex;
  &_justify {
    &_start {
      justify-content: flex-start; }
    &_end {
      justify-content: flex-end; }
    &_center {
      justify-content: center; }
    &_space-between {
      justify-content: space-between; }
    &_space-around {
      justify-content: space-around; } }
  &_align {
    &_start {
      align-items: flex-start; }
    &_end {
      align-items: flex-end; }
    &_center {
      align-items: center; } }
  &_direction {
    &_row {
      flex-direction: row; }
    &_row_reverse {
      flex-direction: row-reverse; }
    &_column {
      flex-direction: column; }
    &_column-reverse {
      flex-direction: column-reverse; } }
  &_wrap {
    flex-wrap: wrap; }
  &_nowrap {
    flex-wrap: nowrap; } }
