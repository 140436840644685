.Btn_theme_saved {
  position: relative;
  &:after {
    content: 'Успешно сохранено!';
    width: 140px;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    position: absolute;
    left: calc(100% + 13px);
    top: calc(50% - 7px);
    cursor: default; } }
