.MainSection {
  padding-bottom: 30px;
  &-Row {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 40px;
    padding-bottom: 15px;
    &_bgGrey {
      background-color: #f8f8f8; }
    &_title {
      margin-bottom: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      .Heading_lvl1 {
        margin-right: 20px; }
      &-lvl2 {
        .Heading_lvl2 {
          margin-bottom: 0; } }
      &_no-mrg {
        .Heading_lvl1, .Heading_lvl2, .Heading_lvl3 {
          margin-bottom: 0; } } }
    &_no-mrg {
      margin-bottom: 0; }
    &_bd {
      border-bottom: 2px solid #ecf2f3; }
    &_noTopPadding {
      padding-top: 0; }
    &_noBottomPadding {
      padding-bottom: 0; } } }
