.Btn {
  background-color: transparent;
  font-size: 14px;
  color: #000000;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center; }
