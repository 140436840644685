.Btn_theme_wait {
  background: #c7c7c7 !important;
  position: relative;
  cursor: no-drop;
  &:after {
    content: 'Ждите...';
    font-size: 14px;
    line-height: 14px;
    color: #808080;
    position: absolute;
    left: calc(100% + 13px);
    top: calc(50% - 7px);
    cursor: default; } }
