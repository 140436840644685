.Error {
  width: 100%;
  padding: 7px 10px;
  background-color: #fce8e8;
  border: 1px solid #ff7f7f;
  border-radius: 3px;
  &-Text {
    font-size: 14px;
    font-weight: 300;
    color: #ff0000; } }
