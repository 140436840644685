//@import "../../../node_modules/bootstrap/scss/bootstrap-grid"
@import "bourbon";
@import "vars";

* {
  outline: none; }

a, p, h1, sup, span {
  color: inherit;
  font-weight: inherit; }

sup {
  font-size: 60%; }

b {
  font-weight: 600; }

svg {
  fill: currentColor; }

body {
  margin: 0;
  padding: 0;
  font-family: $font-default;
  font-size: 16px;
  font-weight: 400;
  color: #000; }

.Container {
  min-width: 1280px;
  max-width: 1366px;
  margin: 0 auto; }


.fancybox-container {
  z-index: 9000; }

.fancybox-content {
  padding: 0; }
