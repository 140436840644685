.Phone {
  &-Only {
    display: inherit !important;
    @include mf(xs) {
      display: none !important; } }
  &-Hidden {
    display: none !important;
    @include mf(xs) {
      display: inherit !important; } } }

@import "../../common.blocks/Btn/index";

@import "../../common.blocks/Select/index";
@import "../../common.blocks/Select/_theme_arrow/index";

@import "../../common.blocks/Input/index";
@import "../../common.blocks/Input/_error/index";
@import "../../common.blocks/Input/_theme_measure/index";

@import "../../common.blocks/Checkbox/_theme_btn/index";

@import "../../common.blocks/Form/index";

@import "../../common.blocks/Logo/index";

@import "../../common.blocks/Label/index";

@import "../../common.blocks/Table/index";

@import "../../common.blocks/Link/index";

@import "../../common.blocks/Flex/index";


@import "../../project.blocks/Btn/index";
@import "../../project.blocks/Btn/_theme_arrow/index";
@import "../../project.blocks/Btn/_theme_green/index";
@import "../../project.blocks/Btn/_theme_grey/index";
@import "../../project.blocks/Btn/_theme_blue/index";
@import "../../project.blocks/Btn/_theme_excell/index";
@import "../../project.blocks/Btn/_size_m/index";

@import "../../common.blocks/Btn/_theme_wait/index";
@import "../../common.blocks/Btn/_theme_saved/index";
@import "../../common.blocks/Btn/_theme_delete/index";
@import "../../common.blocks/Btn/_disable/index";

@import "../../project.blocks/Error/index";
@import "../../project.blocks/Error/_icon/index";

@import "../../project.blocks/Input/index";

@import "../../project.blocks/Select/index";

@import "../../project.blocks/Logout/index";

@import "../../project.blocks/MainSection/index";

@import "../../project.blocks/Heading/index";

@import "../../project.blocks/Card/index";
@import "../../project.blocks/Card/_bgGray/index";
@import "../../project.blocks/Card/_bd/index";
@import "../../project.blocks/Card/_bd/_color_blueChill/index";

@import "../../project.blocks/AddMenu/index";
