.Heading {
  &_lvl1 {
    font-size: 24px;
    font-weight: 500;
    color: #000; }
  &_lvl2 {
    margin-bottom: 15px;
    font-size: 18px;
    color: $main-color; }
  &_lvl3 {
    margin-bottom: 10px;
    font-size: 14px;
    color: $main-color; }
  &_no-margin {
    margin-bottom: 0; } }
