.Checkbox {
  &-Control {
    display: none;
    &:checked + .Checkbox-Label {
      background-color: #0f818e;
      color: #FFFFFF; } }
  &-Label {
    width: 100%;
    height: 100%;
    padding-left: 8px;
    background-color: #f8f8f8;
    font-size: 14px;
    color: #000;
    border: solid 1px rgba(196, 196, 196, 0.5);
    border-radius: 3px;
    display: flex;
    align-items: center;
    cursor: pointer; } }
