.Link {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none; }
  &-dashed {
    border-bottom: 1px dashed #26a9e0;
    &:hover {
      border-bottom: none; } }
  &_hover-underline {
    text-decoration: none;
    &:hover {
      text-decoration: underline; } } }
