.Error-Text_icon {
  padding-left: 28px;
  position: relative;
  &:before {
    content: '!';
    width: 16px;
    height: 16px;
    background-color: #ff0000;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 16px;
    border-radius: 100%;
    position: absolute;
    top: -2px;
    left: 0; } }
