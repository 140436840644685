.Select_theme_arrow {
  .Select-Control {
    padding-right: 35px;
    padding-left: 15px;
    background-color: #f6f6f6;
    background-image: url(/img/SelectArrow.png);
    background-position: calc(100% - 10px)  center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none; } }
