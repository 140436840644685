@charset "UTF-8";
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: https://meyerweb.com
	HTML5 Doctor				:: https://html5doctor.com
	and the HTML5 Boilerplate	:: https://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/*!* Responsive images and other embedded objects
*!
img,
object,
embed {max-width: 100%;}*/
/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	 In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul, ol {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

* {
  outline: none; }

a, p, h1, sup, span {
  color: inherit;
  font-weight: inherit; }

sup {
  font-size: 60%; }

b {
  font-weight: 600; }

svg {
  fill: currentColor; }

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000; }

.Container {
  min-width: 1280px;
  max-width: 1366px;
  margin: 0 auto; }

.fancybox-container {
  z-index: 9000; }

.fancybox-content {
  padding: 0; }

.Phone-Only {
  display: inherit !important; }
  @media (min-width: 480px) {
    .Phone-Only {
      display: none !important; } }

.Phone-Hidden {
  display: none !important; }
  @media (min-width: 480px) {
    .Phone-Hidden {
      display: inherit !important; } }

.Btn {
  font-size: 100%;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center; }

button.Btn {
  display: block;
  text-align: center; }

.Select {
  padding-left: 15px;
  background-color: #fff; }

.Select_theme_arrow .Select-Control {
  padding-right: 35px;
  padding-left: 15px;
  background-color: #f6f6f6;
  background-image: url(/img/SelectArrow.png);
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none; }

.Input {
  display: flex;
  flex-direction: column; }
  .Input-Control {
    padding-left: 20px;
    background-color: #f6f6f6;
    font-size: 14px;
    border: none;
    border-radius: 5px; }

.Input_error {
  background-color: #fed1dd !important; }

.Input_theme_measure .Input-Wrap {
  position: relative; }

.Input_theme_measure .Input-Measure {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  position: absolute;
  top: 8px;
  right: 5px; }

.Input_theme_measure .Input-Control {
  width: 100%; }

.Checkbox-Control {
  display: none; }
  .Checkbox-Control:checked + .Checkbox-Label {
    background-color: #0f818e;
    color: #FFFFFF; }

.Checkbox-Label {
  width: 100%;
  height: 100%;
  padding-left: 8px;
  background-color: #f8f8f8;
  font-size: 14px;
  color: #000;
  border: solid 1px rgba(196, 196, 196, 0.5);
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: pointer; }

.Form-Row {
  margin-bottom: 10px; }
  .Form-Row:last-child {
    margin-bottom: 0; }

.Form-Btn {
  width: 100%;
  margin-top: 16px; }

.Logo-Image {
  max-width: 100%;
  display: block; }

.Label {
  margin-bottom: 3px;
  font-size: 14px;
  color: #898989; }

.Table-Row {
  border-bottom: 1px solid #e9e9e9; }
  @media (min-width: 768px) {
    .Table-Row {
      display: flex; } }
  .Table-Row:last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border: none; }
  .Table-Row_link {
    text-decoration: none; }
    .Table-Row_link:hover {
      background-color: #dbe9eb; }
  .Table-Row_head {
    margin-bottom: 0;
    padding-bottom: 0;
    border-color: transparent;
    display: none; }
    @media (min-width: 768px) {
      .Table-Row_head {
        margin-bottom: 0;
        border-bottom: none;
        display: flex; } }
    .Table-Row_head .Table-Column {
      margin-bottom: 0; }
    .Table-Row_head .Table-Text {
      color: #898989; }
      .Table-Row_head .Table-Text_label {
        display: none; }
        @media (min-width: 768px) {
          .Table-Row_head .Table-Text_label {
            display: inline-block; } }
  .Table-Row:last-child {
    border-bottom: none; }

.Table-Column {
  margin-bottom: 20px;
  padding: 5px 15px 5px 15px; }
  @media (min-width: 768px) {
    .Table-Column {
      margin-bottom: 0; } }
  .Table-Column:last-of-type {
    margin-bottom: 0; }
  .Table-Column_empty {
    margin: 0;
    padding: 0; }

.Table-Text {
  font-size: 14px;
  color: #000000; }
  .Table-Text-Link {
    font-weight: 500;
    text-decoration: none; }
    .Table-Text-Link:hover {
      text-decoration: underline; }
  .Table-Text_label {
    margin-bottom: 10px;
    font-size: 14px;
    color: #898989; }
    @media (min-width: 768px) {
      .Table-Text_label {
        margin-bottom: 0;
        display: none; } }

.Link {
  color: inherit;
  text-decoration: underline;
  cursor: pointer; }
  .Link:hover {
    text-decoration: none; }
  .Link-dashed {
    border-bottom: 1px dashed #26a9e0; }
    .Link-dashed:hover {
      border-bottom: none; }
  .Link_hover-underline {
    text-decoration: none; }
    .Link_hover-underline:hover {
      text-decoration: underline; }

.Flex {
  display: flex; }
  .Flex_justify_start {
    justify-content: flex-start; }
  .Flex_justify_end {
    justify-content: flex-end; }
  .Flex_justify_center {
    justify-content: center; }
  .Flex_justify_space-between {
    justify-content: space-between; }
  .Flex_justify_space-around {
    justify-content: space-around; }
  .Flex_align_start {
    align-items: flex-start; }
  .Flex_align_end {
    align-items: flex-end; }
  .Flex_align_center {
    align-items: center; }
  .Flex_direction_row {
    flex-direction: row; }
  .Flex_direction_row_reverse {
    flex-direction: row-reverse; }
  .Flex_direction_column {
    flex-direction: column; }
  .Flex_direction_column-reverse {
    flex-direction: column-reverse; }
  .Flex_wrap {
    flex-wrap: wrap; }
  .Flex_nowrap {
    flex-wrap: nowrap; }

.Btn {
  background-color: transparent;
  font-size: 14px;
  color: #000000;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center; }

.Btn_theme_arrow {
  width: 187px;
  height: 39px; }
  .Btn_theme_arrow .Btn-Icon_prev {
    margin-right: 20px; }
  .Btn_theme_arrow .Btn-Icon_next {
    margin-left: 20px; }

.Btn_theme_green {
  background-color: #aacb45;
  color: #ffffff; }
  .Btn_theme_green:hover {
    background-color: #8ead30; }

.Btn_theme_grey {
  background-color: #5d5d5d;
  color: #ffffff; }
  .Btn_theme_grey:hover {
    background-color: #444444; }

.Btn_theme_blue {
  background-color: #00abe3;
  color: #ffffff; }
  .Btn_theme_blue:hover {
    background-color: #0085b0; }

.Btn_theme_excell {
  padding: 0 15px;
  display: inline-flex; }
  .Btn_theme_excell .Icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    fill: #fff; }

.Btn_size_m {
  width: 165px;
  height: 36px; }

.Btn_theme_wait {
  background: #c7c7c7 !important;
  position: relative;
  cursor: no-drop; }
  .Btn_theme_wait:after {
    content: 'Ждите...';
    font-size: 14px;
    line-height: 14px;
    color: #808080;
    position: absolute;
    left: calc(100% + 13px);
    top: calc(50% - 7px);
    cursor: default; }

.Btn_theme_saved {
  position: relative; }
  .Btn_theme_saved:after {
    content: 'Успешно сохранено!';
    width: 140px;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    position: absolute;
    left: calc(100% + 13px);
    top: calc(50% - 7px);
    cursor: default; }

.Btn_theme_delete {
  background-color: transparent !important;
  font-size: 14px;
  color: #ff0000;
  text-decoration: underline;
  display: inline-flex; }
  .Btn_theme_delete:hover {
    text-decoration: none; }

.Btn_disable {
  background-color: #C2C2C2;
  cursor: not-allowed; }

.Error {
  width: 100%;
  padding: 7px 10px;
  background-color: #fce8e8;
  border: 1px solid #ff7f7f;
  border-radius: 3px; }
  .Error-Text {
    font-size: 14px;
    font-weight: 300;
    color: #ff0000; }

.Error-Text_icon {
  padding-left: 28px;
  position: relative; }
  .Error-Text_icon:before {
    content: '!';
    width: 16px;
    height: 16px;
    background-color: #ff0000;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 16px;
    border-radius: 100%;
    position: absolute;
    top: -2px;
    left: 0; }

.Input-Control {
  height: 30px;
  border: 1px solid #dadada; }

.Select {
  padding: 0; }
  .Select .Label {
    display: block; }
  .Select-Control {
    height: 29px;
    border: solid 1px rgba(196, 196, 196, 0.5);
    border-radius: 3px;
    display: inline-block; }

.Logout {
  display: flex;
  align-items: center; }
  .Logout-Username {
    margin-right: 12px;
    padding-right: 10px;
    font-size: 14px;
    color: #f1f2ff;
    text-align: right;
    border-right: 1px solid #5ea8cc;
    line-height: 1.2; }
    .Logout-Username-Icon {
      margin: 0 10px -1px 0; }
    .Logout-Username-Post {
      font-size: 12px; }
  .Logout-Btn {
    width: 96px;
    height: 31px;
    background-color: #5ea8cc;
    font-size: 12px;
    color: #ffffff; }
    .Logout-Btn:hover {
      background-color: #3b92bc; }

.MainSection {
  padding-bottom: 30px; }
  .MainSection-Row {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 40px;
    padding-bottom: 15px; }
    .MainSection-Row_bgGrey {
      background-color: #f8f8f8; }
    .MainSection-Row_title {
      margin-bottom: 20px;
      padding-top: 20px;
      padding-bottom: 20px; }
      .MainSection-Row_title .Heading_lvl1 {
        margin-right: 20px; }
      .MainSection-Row_title-lvl2 .Heading_lvl2 {
        margin-bottom: 0; }
      .MainSection-Row_title_no-mrg .Heading_lvl1, .MainSection-Row_title_no-mrg .Heading_lvl2, .MainSection-Row_title_no-mrg .Heading_lvl3 {
        margin-bottom: 0; }
    .MainSection-Row_no-mrg {
      margin-bottom: 0; }
    .MainSection-Row_bd {
      border-bottom: 2px solid #ecf2f3; }
    .MainSection-Row_noTopPadding {
      padding-top: 0; }
    .MainSection-Row_noBottomPadding {
      padding-bottom: 0; }

.Heading_lvl1 {
  font-size: 24px;
  font-weight: 500;
  color: #000; }

.Heading_lvl2 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #008290; }

.Heading_lvl3 {
  margin-bottom: 10px;
  font-size: 14px;
  color: #008290; }

.Heading_no-margin {
  margin-bottom: 0; }

.Card {
  padding: 15px;
  border-radius: 3px;
  background-color: transparent; }

.Card_bgGray {
  background-color: #f8f8f8; }

.Card_bd {
  border: solid 1px rgba(69, 69, 69, 0.5);
  border-radius: 3px; }
  .Card_bd_dashed {
    border-style: dashed; }

.Card_bd_color_blueChill {
  border: solid 1px rgba(15, 129, 142, 0.5); }

.AddMenu {
  width: 100%;
  display: flex;
  position: relative; }
  .AddMenu:before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #e7e7e7;
    position: absolute;
    left: 0;
    bottom: 1px; }
  .AddMenu-Content {
    width: 100%;
    padding-bottom: 3px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap; }
    .AddMenu-Content::-webkit-scrollbar {
      height: 0; }
    .AddMenu-Content::-webkit-scrollbar-thumb {
      background-color: #26393c;
      border-radius: 0; }
      .AddMenu-Content::-webkit-scrollbar-thumb:hover {
        background-color: #c4c4c4; }
    .AddMenu-Content::-webkit-scrollbar-track {
      background-color: #ededed; }
  .AddMenu-Wrap {
    display: flex;
    justify-content: flex-start; }
  .AddMenu-Item {
    height: 40px;
    margin-right: 45px;
    font-size: 18px;
    color: #aeaeae;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer; }
    .AddMenu-Item:last-of-type {
      margin-right: 0; }
    .AddMenu-Item:hover {
      color: #008290; }
    .AddMenu-Item.active {
      color: #008290; }
      .AddMenu-Item.active:before {
        content: '';
        width: 100%;
        height: 2px;
        background: #26a9e0;
        position: absolute;
        left: 0;
        bottom: -2px; }

.Header {
  width: 100%;
  background-color: #26393c; }
  .Header-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .Header-Logo {
    width: 155px;
    padding: 10px 0;
    background-color: #008290;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .Header-Logo .Logo {
      width: 81px;
      height: 53px;
      display: block; }
  .Header-Left {
    display: flex;
    align-items: center; }
  .Header-Nav {
    margin-left: 35px; }

.HeaderNav {
  display: flex; }
  .HeaderNav-Item {
    margin-right: 20px; }
    .HeaderNav-Item:last-child {
      margin-right: 0; }
  .HeaderNav-Link {
    width: 91px;
    height: 39px;
    font-size: 18px;
    color: #ffffff;
    border-radius: 3px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center; }
    .HeaderNav-Link:hover {
      text-decoration: underline; }
    .HeaderNav-Link.active {
      background-color: #008290; }

.ImPolitic {
  max-width: 600px;
  padding: 20px;
  font-weight: 300; }
  .ImPolitic-Title {
    font-size: 20px;
    font-weight: bold; }

.ImCallback {
  width: auto;
  max-width: 320px;
  padding: 20px 10px; }
  @media (min-width: 480px) {
    .ImCallback {
      width: 320px;
      max-width: none;
      padding: 30px 20px 20px; } }
  .ImCallback-Title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #008290;
    text-align: center;
    text-transform: uppercase; }
  .ImCallback-Subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    color: #444444;
    text-align: center; }
  .ImCallback-Input {
    margin-bottom: 10px; }
  .ImCallback-Btn {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px; }
    @media (min-width: 480px) {
      .ImCallback-Btn {
        padding-left: inherit;
        padding-right: inherit; } }

.ImThanks {
  display: none; }
  .ImThanks-Title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #008290;
    text-align: center;
    text-transform: uppercase; }
  .ImThanks-Subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    color: #444444;
    text-align: center; }
  .ImThanks-Btn {
    width: 100%; }

.Footer {
  padding: 17px 0 16px;
  background-color: #333333; }
  .Footer-Body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column; }
    @media (min-width: 480px) {
      .Footer-Body {
        flex-direction: row; } }
  .Footer-Politic {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0.5px; }
    @media (min-width: 480px) {
      .Footer-Politic {
        margin-bottom: 10px; } }
  .Footer-FijiLogo {
    width: 205px; }
    .Footer-FijiLogo img {
      max-width: 100%;
      height: auto;
      display: inline-block; }
