.Logout {
  display: flex;
  align-items: center;
  &-Username {
    margin-right: 12px;
    padding-right: 10px;
    font-size: 14px;
    color: #f1f2ff;
    text-align: right;
    border-right: 1px solid #5ea8cc;
    line-height: 1.2;
    &-Icon {
      margin: 0 10px -1px 0; }
    &-Post {
      font-size: 12px; } }
  &-Btn {
    width: 96px;
    height: 31px;
    background-color: #5ea8cc;
    font-size: 12px;
    color: #ffffff;
    &:hover {
      background-color: darken(#5ea8cc, 10%); } } }
